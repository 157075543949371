import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Certifications | Macrover Engineering Co. Ltd';

},[]);



  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/17.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    paddingTop:'12.5rem'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Certifications</h2>
            
        </div>
    </section>
    
    {/* <section className='section-cover certifications-cover' >
      <div className='all-certs'>
        <img src='images/9001.jpg' className='certification-image'></img>
 <img src='images/14001.jpg' className='certification-image'></img>
 <img src='images/45001.jpg' className='certification-image'></img>
    </div>
    </section> */}
    <section className="cert-cover">
    
        <div>
          <img src='images/31.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/32.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/33.jpg' className='certification-image'></img>
        </div>
         <div>
          <img src='images/34.jpg' className='certification-image'></img>
        </div>

    </section>
    </>
  )
}

export default Clients