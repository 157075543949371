import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import {FaCheckCircle} from 'react-icons/fa';

const WhoWeAre = () => {

useEffect(()=>{
document.title = 'About Us | Macrover Engineering Co. Ltd';

},[]);


  return (
    <>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/5.jpeg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0',
    paddingTop:'12.5rem'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>Who We Are</h2>
            
        </div>
    </section>

     
    <section className='section-cover who-we-are-cover' >
      {/* <h2 className="home-h2 wwa-h2">About Us</h2> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>
                           We aim to produce the fastest turnaround times in the industry while ensuring that the vehicles in our care stay in optimum condition. Quality service is as important as how fast we get the work done. 
</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>
                         We aim at becoming the mechanical team that vehicle and fleet owners trust. With our premium services and cost effective prices, we want our clients to know that <strong>Macrover Engineering Co. Ltd</strong> is the vehicle service center they can rely on.
</p>

                    </article>

                    


                 </div>

          </div>
    </section>

     <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/18.jpeg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text4'>
              {/* <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2> */}
              <h3 className='title-h3'>Our Core Values</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'><strong>H-Honesty:</strong> We work closely with our customers in providing hence providing transparent line of communication.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'><strong>O-Organized:</strong> We have a developed follow-up strategy to gauge performance and ensure we are trustable.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'><strong>P-Professionalism:</strong> We perform at a high level of professionalism through providing quality services and products on time and on budget.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description'> <strong>E-Excellence:</strong> We won’t stop until you say ‘Yes’.</p>
                     </div>
                    

                </div>
                   

           </article>

       </div>

  </section>

      {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Team</h2>
          <p className='clients-para'></p>
       
       
       
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/MTN.jpg' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section>
    <section className='section-cover certifications-cover' >
      <div className='all-certs'>
        <img src='images/9001.jpg' className='certification-image'></img>
 <img src='images/14001.jpg' className='certification-image'></img>
 <img src='images/45001.jpg' className='certification-image'></img>
    </div>
    </section>
    <section className="cert-cover"> 
        <div>
          <img src='images/50.jpg' className='certification-image'></img>
        </div>
    </section> */}
    

    </>
  )
}

export default WhoWeAre