import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Our Team | Macrover Engineering Co. Ltd';

},[]);

  return (
    <>
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           {/* <h2 className="home-h2 team-h2">Our Team</h2> */}
          <p>Number of staff</p>

  <table className='team-table'>
     
    <tr>
     
      <th>Management staff</th>
      <th>Technical staff</th>
      <th>Support</th>
    </tr>

     <tr>
      
      <td>3 members </td>
      <td>8 members</td>
      <td>2 members</td>
    </tr>

  </table>

  <p className='table-headers'>Key personnel and management staff</p>

  <table className='team-table'>
     
    <tr>
      <th>Name</th>
      <th>Qualification</th>
      <th>Years of relevant experience</th>
  
      
    </tr>

     <tr>
      <td>Nabuufu Diana </td>
      <td>B.BA</td>
      <td>2 years</td>
    </tr>
    <tr>
      <td>Kiyega Isaac </td>
      <td>Automotive Engineering</td>
      <td>6 years</td>
    </tr>
    <tr>
      
      <td>Atuhaire Ruth </td>
      <td>B.A SS</td>
      <td>8 years</td>
    </tr>


   

     

   

      

  </table>


         </div>
    </section>
     <section className='section-cover gallery-cover' >
      <p className='chart-p'>Company Structure</p>
       {/* <h2 className="home-h2 gallery-h2 gallery-h2a">Organisational Chart</h2> */}
      <div className='content-container gallery gallery1 content-container2'>
 <article className='gallery-content gallery-content1'>
          <img src='./images/35.jpg' className='gallery-image' alt='image'></img>
         </article>
      </div>

    </section>
    </>
  )
}

export default Team